import React, { useLayoutEffect, useRef } from "react"
import SocialIcon from "../components/SocialIcon"
import Lottie from "react-lottie"
import loadingSpinner from "../../static/lf20_z9gwyc2b.json"

const Home = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: loadingSpinner,
    rendererSettings: {},
  }

  return (
    <>
      <section
        id="home"
        className="flex items-center bg-[#111216] text-[#E4E6EB] dark"
      >
        {/* <div className="w-1/2 h-1/2 bg-primary opacity-50 rounded-[735px] absolute right-0 -bottom-0 blur-[200px]"></div> */}
        <div className="md:px-8 px-4 max-w-screen-xl mx-auto w-full relative">
          <div className="grid grid-cols-12 gap-8 py-20 items-center h-full">
            <div className="lg:col-span-7 col-span-12 content-between grid">
              <div>
                <h2 className="md:text-8xl text-4xl font-bold  text-transparent bg-clip-text bg-gradient-to-r from-[#6FC6FF] to-[#C523FF]">
                  Hi,
                </h2>
                <h2 className="md:leading-normal md:text-8xl text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#6FC6FF] to-[#C523FF]">
                  I’m Milap Dave
                </h2>
                <p className="md:text-2xl my-4 ">
                  A front end developer based in India. I specialize in creating
                  stunning, user-friendly websites using the latest
                  technologies.
                </p>
              </div>
              <SocialIcon />
            </div>
            <div className="items-center md:col-span-5 col-span-12 self-center lottie_aniamtion lg:flex hidden">
              <Lottie options={defaultOptions} height={600} width={600} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
