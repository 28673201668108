import React, { useState, useEffect, useRef } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import FancyTitle from "./FancyTitle"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const query = graphql`
  {
    allContentfulSkills {
      nodes {
        id
        contentful_id
        title
        image {
          file {
            url
          }
        }
      }
    }
  }
`

const Skills = ({ title, subtitle }) => {
  const data = useStaticQuery(query)

  const skills = data.allContentfulSkills.nodes

  return (
    <section className="md:px-8 px-4 max-w-screen-xl mx-auto w-full relative">
      <div className="md:py-10 py-3">
        <div className="md:col-span-12 col-span-12 grid">
          {title && <FancyTitle title={title} subtitle={subtitle} />}
          <ul className="lg:grid-cols-4 grid grid-cols-2 gap-8 py-10 sm:grid-cols-4">
            {skills.map((item, index) => (
              <li key={index} className="rounded-md relative">
                <div className="hidden gradient-border gradient-border-dark dark:block"></div>
                <div className="dark:hidden gradient-border gradient-border-light"></div>
                <div className="app-card border border-[#e4e4e7] bg-white dark:border-[#27272A] dark:bg-[#18181b] ">
                  <div className="p-5 text-center">
                    {item.image && (
                      <div className="mb-7 flex items-center justify-center">
                        <img
                          className="m-auto w-16"
                          src={item?.image?.file?.url}
                          alt={item?.title}
                        />
                      </div>
                    )}
                    <h2 className="text-xl">{item?.title}</h2>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Skills
