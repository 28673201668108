import React, { useState, useEffect, useRef } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import FancyTitle from "./FancyTitle"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ServiceItem from "./ServiceItem"

export const query = graphql`
  {
    allContentfulServices {
      nodes {
        id
        contentful_id
        title
        content
        image {
          id
          gatsbyImageData
          file {
            url
          }
        }
      }
    }
  }
`

const Services = ({ title }) => {
  const data = useStaticQuery(query)

  const services = data.allContentfulServices.nodes

  return (
    <section className="md:px-8 px-4 max-w-screen-xl mx-auto w-full relative">
      <div className="grid grid-cols-12 gap-8 md:py-10 py-3">
        <div className="md:col-span-12 col-span-12 grid">
          {title && <FancyTitle title={title} />}
          <ul className="lg:grid-cols-3 grid grid-cols-1 gap-8 py-10 sm:grid-cols-2">
            {services.map((item, index) => (
              <ServiceItem key={item.id} index={index} data={item} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Services
