import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

import React from "react"

const ServiceItem = ({data}) => {
    console.log(data,'item')

    // const image = getImage(data.image)

  return (
    <li className="rounded-md relative">
      <div className="hidden gradient-border gradient-border-dark dark:block"></div>
      <div className="dark:hidden gradient-border gradient-border-light"></div>
      {/* dark:hover:bg-[#27272a] */}
      <div className="app-card border border-[#e4e4e7] bg-white dark:border-[#27272A] hover:border-transparent dark:bg-[#18181b] ">
        <div className="p-8">
          <div className="w-16 mb-7">
            <img
                    className=""
                    src={data.image.file.url}
                    alt={data?.title}
                />
          </div>
          <h2 className="text-3xl mb-4">{data?.title}</h2>
          <p className="dark:text-gray-300">
            {data?.content}
          </p>
        </div>
      </div>
    </li>
  )
}

export default ServiceItem
