import React from "react"
import Layout from "../components/Layout"
import Home from "./Home"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import Blog from "../components/Blog"
import Services from '../components/Services'
import Skills from "../components/Skills"
export default function Index() {
  return (
    <Layout>
      <Seo title="Milap Dave | Front end developer" />
      <Home />
      <Services title={'My Expertise'}/>
      <Projects
        title="My Work"
        subtitle={
          "Thing's I've made or learned at the companies I have worked for."
        }
        limit={5}
        isHome={false}
      />
      {/* <Blog title={'Blog'} limit={3} isHome={true}  subtitle={"Essays, learning, and other miscellaneous goodies."}/> */}
    </Layout>
  )
}
