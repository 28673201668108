import React from 'react'

const SocialIcon = ({fontSize = 'md:text-lg text-xs'}) => {
  return (
    <div className="overflow-hidden">
        <div className={`flex gap-3 text-md uppercase font-semibold py-4 ${fontSize}`}>
            <a className="underline underline-offset-4 hover:no-underline" href="mailto:milapdave15@gmail.com?Subject=Hello Milap | Front End Developer">Email</a>
            <a className="underline underline-offset-4 hover:no-underline" rel="noreferrer noopener" href="https://github.com/milapdave" target="_blank">github</a>
            <a className="underline underline-offset-4 hover:no-underline" rel="noreferrer noopener" href="https://www.linkedin.com/in/milapdave/" target="_blank">linkedin</a>
            <a className="underline underline-offset-4 hover:no-underline" rel="noreferrer noopener" href="https://www.facebook.com/milapdave" target="_blank">facebook</a>
            <a className="underline underline-offset-4 hover:no-underline" rel="noreferrer noopener" href="https://www.youtube.com/@milapdave" target="_blank">youtube</a>
        </div>
    </div>
  )
}

export default SocialIcon
