import React, { useRef, useLayoutEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import slugify from "slugify"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import FancyTitle from "./FancyTitle"

const Bold = ({ children }) => <span className="font-semibold">{children}</span>
const Text = ({ children }) => (
  <p className="md:text-lg text-md align-center mb-5 ">{children}</p>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].marks[0]?.type === "code") {
        return (
          <div className="relative flex flex-col">
            <div className="flex flex-grow flex-col">
              <div className="flex flex-col items-start  whitespace-pre-wrap">
                <div className="markdown prose w-full break-words dark:prose-invert dark">
                  <pre>
                    <div className="bg-black mb-4 rounded-md">
                      <div className="p-4 overflow-y-auto">
                        <code className="!whitespace-pre hljs language-css">
                          {node.content[0].value}
                        </code>
                      </div>
                    </div>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        )
      }

      return <Text>{children}</Text>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      // Extract videoId from the URL
      const match =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
          node.data.uri
        )
      const videoId = match && match[7].length === 11 ? match[7] : null
      if (node.data.uri.indexOf("youtube.com") !== -1) {
        return (
          <section className="video-container">
            <iframe
              className="video"
              title={`https://youtube.com/embed/${videoId}`}
              src={`https://youtube.com/embed/${videoId}`}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </section>
        )
      } else {
        return (
          <a href={uri} className="underline text-blue-700">
            {children}
          </a>
        )
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const url = node?.data?.target?.file?.url
      const alt = node?.data?.target?.title
      if (node.data.target.file.url) {
        return <img className="mb-5" alt={alt} src={url} />
      }
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="text-2xl mt-10 mb-3 font-semibold">{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className="text-xl mt-10 mb-3 font-semibold">{children}</h3>
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className="list-decimal pl-4">{children}</ol>
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className="pl-5 list-disc text-md mb-5">{children}</ul>
    },
    [BLOCKS.CODE]: (node, children) => {
      return <code className="border-2">{children}</code>
    },
  },
}

export const query = graphql`
  {
    allContentfulProjects {
      nodes {
        title
        projectUrl
        content {
          raw
        }
        contentful_id
        createdAt
        featuredImage {
          gatsbyImageData
          title
        }
      }
    }
  }
`

const Project = ({ title, subtitle, limit = 100, isHome }) => {
  const data = useStaticQuery(query)
  const projectList = data.allContentfulProjects.nodes
  return (
    <section
      id="work"
      className="w-full max-w-screen-xl mx-auto box md:px-8 p-4"
    >
      <div className="grid grid-cols-12 gap-8 md:py-10 py-3">
        <div className="md:col-span-12 col-span-12 grid">
          {title && <FancyTitle title={title} subtitle={subtitle} />}
          <div className="">
            {projectList.slice(0, limit).map(item => {
              const { id, title, featuredImage, content, projectUrl } = item
              const image = getImage(featuredImage)
              const slug = slugify(title, { lower: true })
              return (
                <div
                  key={id}
                  className="flex items-center min-h-[381px]  my-10 rounded-md overflow-hidden border border-[#e4e4e7] bg-white dark:border-[#27272A]  dark:bg-[#18181b] "
                >
                  <div className="md:grid grid-cols-2 gap-5">
                    <div className="md:order-2 mb-5 md:mb-0">
                      <AniLink fade duration={1} to={`/${slug}/`}>
                        <GatsbyImage
                          className="block h-full"
                          image={image}
                          alt={title}
                        />
                      </AniLink>
                    </div>
                    <div className="md:p-8 p-8  flex  flex-col justify-center md:order-1">
                      <div className="">
                        <h2 className="md:text-2xl text-xl font-semibold mt-1 mb-4">
                          <AniLink fade duration={1} to={`/${slug}/`}>
                            {title}
                          </AniLink>
                        </h2>
                        <div className="">
                          {renderRichText(content, options)}
                        </div>
                      </div>
                      <span className="mb-5 h-[1px] w-[100px] bg-slate-600 flex"></span>
                      <div className="inline-flex">
                        <a
                          className="text-md font-semibold underline underline-offset-4 hover:no-underline"
                          rel="noreferrer"
                          href={projectUrl}
                          target="_blank"
                        >
                          Visit website
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {isHome && (
            <AniLink fade duration={1} to={"/work"} className="text-2xl py-10">
              View all projects →
            </AniLink>
          )}
        </div>
      </div>
    </section>
  )
}

export default Project
